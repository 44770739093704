import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'


class BannerSection extends React.Component {


  render() {

    return (
      <section className="banner alngpad wallet-banner">  
        <div className="container">
          <div className="row table-content">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 left-side mb-0 mb-md-3 mb-lg-0">
                <h1 className="main-heading"><span className='bluecolor'>IDO</span> Development Company</h1>
                <p className="pharagraph">Join the future of finance today !! </p>
                <p className='pharagraph mb-0'>Coinsclone is the leading IDO Development Company that offers excellent IDO development services tailored to your needs. Our developers assist you throughout the entire process from White paper creation to successful deployment. Partner with us now and create your opportunity to knock on the gates and get ahead in the DEX ecosystem.</p>
                <a href="https://www.coinsclone.com/contact-us/" className="bluebtn mt-4">Grab a free demo</a>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 right-side">
              <StaticImage 
                src="https://coinsclone.mo.cloudinary.net/images/ido/ido-development-company.webp"
                alt="ido development company"
                className='d-none d-md-block float-end'
                width={545}
              />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default BannerSection