import React from "react"

class Benificial extends React.Component {

  render() {
    return (

      <section className="fine-box pt-100 mb-0">
        <div className="gray-bg">
          <div className="container"> 
            <h2 className="heading-h2 text-center">Our Sequential IDO <span className="bluecolor">Development Approach</span>
            </h2>
            <p className="text-center mb-2">
            The IDO Development process is both intriguing and highly detail-oriented process. We're committed to supporting you through every phase, from inception to maintenance pledging a smooth user experience.
            </p>
            <div className="d-lg-flex flex-wrap add-one px-0">
              <div className="square" >
                <h3>Strategizing</h3>
                <p className="pharagraph">
                The first step is to connect with our clients for a little brainstorming session, where we collect, process, and analyze the business needs of our clients. We strategize the approach of the entire development process.
                </p>
              </div>
              <div className="square" >
                <h3>White paper Creation</h3>
                <p className="pharagraph">
                A clear, legible white paper carries the project’s entire process, beneficial factors, and procedures. The white paper creation serves as a strategy tool for grabbing the spotlight and attention of the investors.
                </p>
              </div>
              <div className="square" >
                <h3>Tech development</h3>
                <p className="pharagraph">
                Seamlessly develop the IDO tokens supporting suitable blockchain networks. Our developers also aid in launching the tokens on the essential platforms thereby fostering trust among the investors and enhancing the fundraising process of the project.
                </p>
              </div>
              <div className="square" >
                <h3>Token Development & Listing</h3>
                <p className="pharagraph">
                Seamlessly develop the IDO tokens supporting suitable blockchain networks. Our developers also aid in launching the tokens on the essential platforms thereby fostering trust among the investors and enhancing the fundraising process of the project.
                </p>
              </div>
              <div className="square" >
                <h3>Testing</h3>
                <p className="pharagraph">
                Our team of testers is involved in rigorous testing of the platform, making sure that every requirement is met. Also here, we ensure that the developed product performs at its best without any glitches or bugs and resolving in case of any.
                </p>
              </div>
              <div className="square" >
                <h3>Deployment</h3>
                <p className="pharagraph">
                From launching, deploying, and promoting the platform on multiple channels to giving Post-launch support and maintenance, our team provides continuous support. Also, throughout the process, we keep ensuring faultless and transparent operations.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Benificial
