import React from 'react'



class Whatis extends React.Component {


  render() {

    return (
      <section className="whatis pt-0">
        <div className='gray-bg'>
        <div className="container">
                <h2 className="heading-h2 text-center">Initial DEX Offering Development - An <span className='bluecolor'>Effective Fundraising </span>Model </h2>
            <div className="text-center">
              <p className="pharagraph text-center">Being the top IDO Development Company, we excel in providing top-notch services while incorporating necessary features. IDO - Initial DEX Offering is the collaboration of the blockchain network’s advancement and latest technological enhancements. The IDO is a fundraising mechanism that collects funds by launching a project on a decentralized platform to grab the attention of investors. Any form of digital asset from cryptocurrencies to NFTs like virtual arts, music, or games can be created to support the IDO process.
              </p>
              <p className="pharagraph text-center">Our premier IDO development company offers a comprehensive suite of services for startups looking to raise funds. We provide end-to-end solutions with smart contract development, token creation, and liquidity pool setup that empower projects to launch with confidence. Our maestro UI/UX team excels in designing user-friendly interfaces and implementing robust security measures. Driven by innovation, we ensure our clients can launch successful campaigns while sticking to regulatory standards.
              </p>
              <p className="pharagraph text-center mb-0">Partner with us and give ignition to your business ideas. 
              </p>
            </div>
        </div>
        </div>
      </section>
    )
  }
}

export default Whatis