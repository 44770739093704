import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'


class WhyChoose extends React.Component {

  render() {

    return (
      <section className="whatis pt-100 mb-0 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2">What Makes Us A Reliable Choice For <span className="bluecolor">IDO Development Company?</span></h2>
              <p className="pharagraph">Coinsclone stands out as the premier IDO development company by nearly completing 150+ successful projects. We offer IDO Development services with our professional's extensive experience and expertise in blockchain technology. Our professionals ensure robust and secure IDO development services tailored to the unique needs of each client. We are the type of company that puts customer needs first and encourages innovative ideas.</p>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-6 col-md-12 text-center">
              <StaticImage 
                src="https://coinsclone.mo.cloudinary.net/images/ido/what-makes-ido.webp"
                alt="best ido development company"
                placeholder='none'
                className='mt-3 mb-3'
                width={532}
              />
            </div>
            <div className="col-lg-6 col-md-12">
              <p className="pharagraph">Staying at the front of industry trends and incorporating cutting-edge features, we enhance user experience and functionality. Our commitment to client satisfaction is notable, as we offer personalized consultation and care in each development process. Furthermore, our track record of successful projects and satisfied clients speaks about the quality of our services. Your today little startup dream might turn into a successful story that is spoken tomorrow. As your trusted partner, we help you to pursue your crypto business passion.
              </p>
              <b>Let’s together Build a Better Business</b>
              <ul className='mt-3'>
                <li>24/7 Customer Maintainance & Support.</li>
                <li>Security Safeguarding mechanisms.</li>
                <li>Usage of advanced & modern tech stacks.</li>
                <li>Budgeted and Transparent Approach.</li>
                <li>Hassle-free Customizable solutions.</li>
                <li>11+ years of professional experience.</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default WhyChoose