import React from 'react';
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
import BannerCrypto from '../components/ido/Banner';
import Whatis from '../components/ido/Whatis';
import DevService from '../components/ido/DevServices';
import WhyChoose from '../components/ido/WhyChoose';
import FaqSection from '../components/ido/FaqSection';
import UserFriendly from '../components/Cryptoexchange/UserFriendly';
import Benificial from '../components/ido/Benificial';
import TechStack from '../components/ido/TechStack';
import DevProcess from '../components/ido/DevProcess';
import FeatureBenefits from '../components/ido/FeatureBenefits';
import VariousBlock from '../components/ido/VariousBlock';




const IdoDev = () => (
  <section className="cryptoex">
    <Layout>
      <Helmet>
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.coinsclone.com/ido-development-company/" />
        <title>IDO Development Company - Coinsclone</title>
        <meta name="description" content="Coinsclone is the formest IDO Development Company that offers IDO Platform Development Services for  Initial Liquidity Offering (ILO) to DEX for the fundraising process." />
        <meta name="keywords" content="IDO Development Company, IDO Development Services" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="IDO Development Company - Coinsclone" />
        <meta property="og:description" content="Coinsclone is the formest IDO Development Company that offers IDO Platform Development Services for  Initial Liquidity Offering (ILO) to DEX for the fundraising process." />
        <meta property="og:url" content="https://www.coinsclone.com/ido-development-company/" />
        <meta property="og:image" content="https://coinsclone.mo.cloudinary.net/images/ido/ido-development-company.webp" />
        <meta property="og:site_name" content="Coinsclone" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Coinsclone" />
        <meta name="twitter:creator" content="@Coinsclone" />
        <meta name="twitter:description" content="Coinsclone is the formest IDO Development Company that offers IDO Platform Development Services for  Initial Liquidity Offering (ILO) to DEX for the fundraising process." />
        <meta name="twitter:title" content="IDO Development Company - Coinsclone" />
        <meta name="twitter:image" content="https://coinsclone.mo.cloudinary.net/images/ido/ido-development-company.webp" />
      </Helmet>
      
      <BannerCrypto />
      <div className="breadcrumb wallet-banner mb-0">
        <div className="container">
          <span><a href="https://www.coinsclone.com/">Home</a> | IDO Development Company</span>
        </div>
      </div>
      <Whatis />
      <DevProcess />
      <Benificial />
      <FeatureBenefits />
      <DevService />
      <VariousBlock />
      <WhyChoose />
      <TechStack />
      <FaqSection />
      <UserFriendly />
    </Layout>
  </section>
)

export default IdoDev
