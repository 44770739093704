import React, { useState } from "react";
import { Accordion, Container } from 'react-bootstrap'



const FaqSection = () => {


  const [activeId, setActiveId] = useState('');

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <section className="faq pt-100 mb-5">
      <Container>
        <h2 className="heading-h2 text-center">Frequently Asked Questions</h2>
        <Accordion defaultActiveKey={activeId}>
            <div className={activeId === '0' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">

                <Accordion.Toggle onClick={() => toggleActive('0')} className="panel-toggle" eventKey="0">
                What is IDO Development?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="0">
                <div className="panel-body card-body">IDO development is a fundraising model that allows to list the IDO projects in a DEX platform and generates capital from investors in a decentralized manner. In short, IDO development is a process that supports the DEX platform incorporating smart contracts and other advancements to access fundraising methodologies.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '1' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('1')} className="panel-toggle"  eventKey="1">
                Why is Coinsclone the Best IDO Development Company?
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey="1">
                <div className="panel-body card-body">Coinsclone is one of the ideal IDO development companies, by being a pro in providing crypto-related services for nearly a decade. We prioritize our client’s business requirements thereby creating a platform that meets both user and client’s needs. With professional experts, we provide the utmost support from planning to post-deployment.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '2' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('2')} className="panel-toggle"  eventKey="2">
                How much Does it cost IDO Development?  
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="2">
                <div className="panel-body card-body">The IDO Development approximately costs between <b>$10,000 to $20,000</b>. Certainly, this cost might change accordingly depending on various factors like integrated tech stacks, development needs, and other required needs of the clients.
                </div>
              </Accordion.Collapse>
            </div> 
            <div className={activeId === '3' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('3')} className="panel-toggle"  eventKey="3">
                How Does IDO Work?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="3">
                <div className="panel-body card-body">The users can connect their crypto wallets to the DEX platform supporting IDO, enabling them to trade tokens. The decentralized and open network nature allows for attracting investors globally.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '4' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('4')} className="panel-toggle"  eventKey="4">
                How to make money from IDO?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="4">
                <div className="panel-body card-body">As the creator of the IDO, you can earn benefits from the token sale by charging a small fee post-launch. Also, you gain commissions from pre-selling the tokens before launch to suited and selected investors. This IDO development allows interested project investors to trade the IDO tokens immediately after the launch on the DEX platform. Thus the investor can buy a high number of tokens at a lower cost and later as the token value increases, can sell those tokens at a high rate to make a profit.
                </div>
              </Accordion.Collapse>
            </div>
        </Accordion> 
      </Container>
    </section>
  )
}

export default FaqSection