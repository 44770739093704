import React from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'


const FeatureBenefits = () => {


  return (
    <section className="customs ido pt-100 mb-0">
      <div className="container">
        <div className="row">
            <h2 className="heading-h2 text-center"><span className="bluecolor">Leveraging Benefits</span> Of Our IDO Development Services
            </h2>
            <p className='text-center'>The IDO development delivers unparalleled benefits for both the users and our clients, effortlessly grabbing the attention within the digital landscape.</p>
            <div className='d-flex flex-wrap justify-content-center top'>
                <div className='custom-blk'>
                    <h3>Instant Trading</h3>
                    <p class="pharagraph">From the moment of the launch, the investors can engage and start trading the tokens. The IDO platform lets users list the tokens to be transferred and engage in transactions swiftly.  The users benefit by buying tokens immediately after its launch.</p>
                </div>
                <div className='custom-blk'>
                    <h3>Lower Cost</h3>
                    <p class="pharagraph">No involvement of centralized regulatory methods and other centralized platforms can significantly reduce costs. By launching an IDO you are only dealing with Decentralized Exchange (DEX)  platforms which leads to minimal expenses.</p>
                </div>
                <div className='custom-blk'> 
                    <h3>Automated Market Maker</h3>
                    <p class="pharagraph">The DEX platform that supports your IDO is built using Automated Market Maker which self-regulates the entire process. These codes ensure the platform's security and reliability, thereby protecting it from malicious attacks and predicting the token prices.</p>
                </div>
                <div className='custom-blk'>
                    <h3>Fair Fundraising</h3>
                    <p class="pharagraph">The IDO development offers a transparent fundraising process eliminating the need to rely on traditional centralized authority to launch the IDO tokens. The investors buy a large number of tokens at a low cost and then can sell them at a high value.</p>
                </div>
                <div className='custom-blk'>
                    <h3>Immediate liquidity</h3>
                    <p class="pharagraph">The IDO provides opportunities for a high distribution of tokens among the ecosystem resulting in increased liquidity. The liquidity thereby increases overall user experience and encourages more trading activity in the platform eliminating price fluctuations.</p>
                </div>
                <div className='custom-blk'>
                    <h3>Global Reach</h3>
                    <p class="pharagraph">Decentralized Exchange platform allows the involvement of a wide range of global audiences. Regardless of the geographical location with internet access and investment interest, anyone can support the IDO tokenization process.</p>
                </div>
            </div>
        </div>
        <div className="mt-3 text-center">
                <ButtonComponent />
        </div>
      </div>
    </section>
  )
}

export default FeatureBenefits