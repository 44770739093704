import React from "react"

class VariousBlock extends React.Component {

  render() {
    return (

      <section className="dapps ido pt-100 mb-0">
        <div className="container">
            <h2 className="heading-h2 text-center"><span className="bluecolor">Various Blockchains </span>For Our IDO Development Services</h2>
            <p className='text-center'>We combine a wide range of ideal blockchain network options for your effective and potential IDO Development.</p>
          <div className="row mb-3">
                    <div className='d-flex flex-wrap justify-content-center px-0'>
                        <div className='fea-card'>
                            <h3>Ethereum</h3>
                            <p className='pharagraph'>Ethereum’s token standards and the network’s scalability and security allow a seamless experience making this one of the most preferred options for your IDO development.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>Binance Smart Chain</h3>
                            <p className='pharagraph'>The robust safeguard measures, regulatory audits, trust, and ample number of dependable users to Binance smart chain blockchain make it one of the best picks for your IDO Development.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>Polygon</h3>
                            <p className='pharagraph'>Polygon offers extensive beneficial factors for your Initial DEX Offerings from pre-launch to post-deployment. An IDO and Polygon is one of the best combos you can quickly go with.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>Tron</h3>
                            <p className='pharagraph'>Tron’s speed, scalability, and efficiency make it an ideal network for IDO Creation. This network’s nature offers investors with efficient fundraising system and liquidity provisions.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>Solana</h3>
                            <p className='pharagraph'>An IDO platform on Solana will open the door to endless opportunities. Empower your project with scalable and secured performance within the Solana ecosystem.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>Waves</h3>
                            <p className='pharagraph'>Waves is known for its speed and user-friendliness. Regardless of its technical expertise, this blockchain platform has a good amount of trust among many crypto enthusiasts.</p>
                        </div>
                    </div>
          </div>
        </div>
      </section>
    )
  }
}

export default VariousBlock
